@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

@font-face {
    font-family: 'alcubierreregular';
    src: url('/fonts/alcubierre-webfont.woff2') format('woff2'),
         url('/fonts/alcubierre-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body {
  height: 100%;
}

// #container {
//   min-height: 100%;
// }

.article-image {
  margin-bottom: @line-height-computed;
}

.article-image.align-left {
  padding-left: 0;
  padding-right: 15px;
}

.language-switcher{
  position: absolute;
  right: 20px;
  top: 20px;
}

.article-image.align-right {
  padding-right: 0;
  padding-left: 15px;
}

.article-image.align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-full {

}

.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}

.pull-quote {
  font-size: @font-size-large;
  border-top: 3px solid @text-color;
  border-bottom: 3px solid @text-color;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  text-align: center;
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.article {
  padding-bottom: @padding-large-vertical;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.article-body {
  .clearfix();
}

.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

#footer {
  background: @gray;
}

.site-footer {
  background: @gray;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.site-header {
  background: @gray;
  margin-bottom: @line-height-computed;
}

// Nav bar dropdown

.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 28px;
  text-align: center;
  margin-bottom: @font-size-h1;

}

.share-btn {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="message"] {
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }

}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  a:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}

#video {
  overflow:hidden;
}

small::before, .small::before {
  content: '\2014 \00A0';
}

#content {
    padding: 260px 0 0px !important;
}

.room ul li {
    text-align: left;
}

// .flowfeeds-item.flowfeeds-type-instagram {
//   position:relative !important;
// }

.flowfeeds-container {
    max-width: 33.33%;
}

img.flowfeeds-image {
    width: 100%;
}

#logo {
  height:140px !important;

  img {
    width: 130px;
}
}

div#header {
    min-height: 145px;
}

.hide-header {
    top: -145px !important;
}

div#hotel-info {
  position: relative;
  top: 50%;
  transform: translateY(100%);
}

.buttons {
  position:absolute;
  bottom:0px;
  width:100%;
  margin-bottom:0px;
}

.grid {
  background:none !important;
}

.room {
  ul.details {
  margin-bottom:0px;
}
[class~='details']:last-of-type {
  margin-bottom:30px;
}
}

.sidebar-heading {
  margin-top:5px;
  font-weight:normal;
  text-transform: none;
}

.side, article {
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontic-hotel";
    width: auto;
    font-size: inherit;
    text-decoration: inherit;
}
}

#contactblock {
  text-align:center;
  padding-bottom:40px;
  padding-top:20px;
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-size: 3em;
}
}

input#mc-embedded-subscribe {
    margin-top: 15px;
}

.gmap {
  padding-bottom: 300px;
}

section#map {
    margin-bottom: -80px;
}

.header .tint {
  display:none;
}

#contact span {
    font-size: 1em !important;
}

.heading {
    display: none;
}

#contact i {
  margin-right:0px !important;
}

.col-sm-4#fields-formBody-field, .col-sm-4#fields-recaptcha-field {
    width: 100%;
}

.input-group {
    width: 100%;
}

.row.form {
  margin-bottom:50px;
}

@media (max-width: @screen-sm) {
a.btn.btn-default.megamenu.pull-left {
  color:#ffffff;
}
}

.mega {
  padding-bottom:50px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top:0px !important;
  font-family:@font-family-headings !important;
}

h4 {
  font-size:@font-size-h4 !important;
}

h5 {
    padding: 15px 0px;
}

p {
  margin-bottom:2em !important;
}

.primary-background a {
  color:#ffffff;
  &:hover, &:focus {
    color:#888888;
  }
}

#products {
  padding:80px 0px;
}

#reservation {
  max-height:100%;
  overflow-y:auto;
  #reservation-container {
    position:relative !important;
    top:0px !important;
    transform:none !important;
  }
  .fsBody, .fsForm {
  background-color:#949300 !important;
  border:none !important;
  color:#ffffff !important;
  border-radius:4px !important;
  textarea, select, input {
    background: rgba(0,0,0,0.1) !important;
    color:#ffffff !important;
    border:none !important;
    border-radius:4px !important;
    padding: 7px 15px !important;
    font-size: 14px !important;
    line-height: 1.71428571 !important;
    border-radius: 4px !important;
  }
  select, input {
    height: 39px !important;
  }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color:#fff;
  }
  input::-moz-placeholder, textarea::-moz-placeholder {
    color:#fff;
  }
  input:-moz-placeholder, textarea:-moz-placeholder {
    color:#fff;
  }
  input:-ms-input-placeholder, textarea:-ms-textarea-placeholder {
    color:#fff;
  }
  input#fsSubmitButton2606349 {
    background: #222 !important;
    color: #fff !important;
    text-transform:uppercase !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 7px 15px;
    font-size: 14px;
    line-height: 1.71428571;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  div#fsCaptcha2606349 {
    background:#ffffff !important;
  }
}
.input-group-addon {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 10%;
    padding: 12px 10px;
  }
  div#fsCell49783676, div#fsCell49783677, div#fsCell49783718 {
    position:relative;
  }
}

.contactform {
  .fsBody, .fsForm {
    padding:0px !important;
  }
  div#fsCell49783676, div#fsCell49783677 {
    width: 32%;
    float: left;
    margin-right: 2%;
    position:relative;
}
  div#fsCell49783718 {
    width:32%;
    float:left;
    position:relative;
  }
  div#fsCell49783726 {
    width:66%;
    margin-right:2%;
    float:left;
  }
  div#fsCell49783682 {
    float:left;
  }
  textarea, select, input {
    padding: 7px 15px !important;
    font-size: 14px !important;
    line-height: 1.71428571 !important;
    border-radius: 4px !important;
  }
  .input-group-addon {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 10%;
    padding: 12px 10px;
  }
}

.modal-backdrop.in {
    display: none;
}

.modal-dialog {
    width: 80% !important;
    margin:10% auto !important;
}

.modal-header {
  border:none !important;
}

img.profile {
  width:100%; 
  max-width:500px; 
  border-radius:500px;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.modal-body {
  .owl-controls {
    position:absolute;
    top:-65px;
    width:100%;
  }
}

.landing {
  .owl-controls {
    position:absolute;
    top:-85px;
    width:100%;
  }
}

.credit-social {
  font-size:20px;
}

.titleimage {
  position:relative;
  width:100%;
  background-size:cover;
  background-repeat:no-repeat;
  .tint {
    background: rgba(163,163,163,0.6) !important;
    height:100%;
  }
  .toptitle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    .container {
      height:100%;
      article {
        position: relative;
        top:50%;
        transform: translateY(-50%);
      }
    }
  }
}

.tint {
  display: block;
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
  opacity:0.7;
}

#home-reviews.parallax {
  h5, blockquote, p, .latest-review blockquote i {
    color:#ffffff;
  }
  h1::after {
    background: #ffffff;
  }
}

.fotorama {
  max-width:500px;
  margin-right:auto;
  margin-left:auto;
}

.green {
  color:#949300;
}

video {
  width:100%;
}

#video {
  height:auto !important;
}

.price {
  text-align:center;
}

@media (max-width: 767px) {
#header ul li#hotel-phone, #header ul li#hotel-email {
    display:block !important;
}
#header ul li:first-of-type {
    margin: 0px;
}
}

h4 span:first-of-type {
    margin-top: 1.5em !important;
}

.datewrapper {
  display:table;
}

.datepicker {
  position:absolute !important;
}

ul.header {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  bottom: 25px;
}